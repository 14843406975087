@import "@/styles/variables";

.phoneCodeSelect {
  width: 60px;
  max-width: 60px;
  min-width: 60px;

  .phoneCodeSelect__control {
    border-radius: $border-radius-base;
    border: 1px solid $color-gray;
    padding: 12px 0;
  }

  .phoneCodeSelect__control--is-focused,
  .phoneCodeSelect__control--is-focused:hover {
    border-radius: $border-radius-base;
    border-color: $color-green-main;
    box-shadow: 0 0 6px 2px #417054;
  }


  .phoneCodeSelect__option:hover {
    background-color: $color-gray;
  }

  .phoneCodeSelect__option--is-selected,
  .phoneCodeSelect__option--is-selected:hover {
    background-color: $color-green-main;
    color: $font-color-light;
  }

  .phoneCodeSelect__menu {
    width: 140px;
  }

  .phoneCodeSelect__single-value {
    text-align: center;
  }
}
